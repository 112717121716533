import { useEffect, useState } from 'react';
import { getPromos } from '../services/promosService';
import Card from '../components/carousel/Card';
import copys from '../utils/copys';
import ScreenLoading from './ScreenLoading';
import { useAuthContext } from '../hooks/useAuthContext';
import gtmService from '../services/gtmService';
import { usePromoContext } from '../hooks/usePromoContext';
import { useToast } from '../hooks/useToast';
import { toastTypes } from '../utils/constants';
import * as Sentry from '@sentry/react'


export default function ScreenHome() {

  const [promos,setPromos] = useState([]);
  const [showLoading,setShowLoading] =useState(true);
  const {userData}= useAuthContext();
  const {setPromoData} = usePromoContext();
  const {showToast} = useToast();

  useEffect(()=>{

    setPromoData({promoId:0})

    if(userData.isLogged){
      gtmService.pushPageView('/cliente-home', 'Cliente: Home')
    }else{
      gtmService.pushPageView('/cliente-inicio','Cliente: Inicio')
    }

    const loadPromos = async()=>{
      const {successful, data, errors, message} = await getPromos();
      setTimeout(()=>setShowLoading(false),500)
      if(successful){
        setPromos(data);
      }else{
        console.log(errors);
        showToast(toastTypes.ERROR,copys.toastErrorMessageDefault);
        gtmService.pushNotification('error',message)
        Sentry.captureException(errors, {
          tags: {
              flow: 'load_promotions',
              user_id: userData?.user?.id,
          },
      });
      }
    };

    loadPromos();

    // Set interval every 15 seconds to check if coupon has been exchanged 
    const intervalId = setInterval(async()=>{
      if(document.visibilityState==='visible'){
        const {successful, data, errors} = await getPromos();
        if(successful){
          setPromos(data);
        }else{
          console.log(errors);
          Sentry.captureException(errors, {
            tags: {
                flow: 'refresh promotions',
                user_id: userData?.user?.id,
            },
        });
        }
      }
    }, 10000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  },[]);

  return (
    <div className='flex flex-col items-center gap-5 justify-start pt-3 pb-5 min-h-screen'>
        {
          showLoading && <ScreenLoading isHome/>
        }
        <div className="flex items-center px-6 gap-1">
          <div className="shrink-0 text-wrap w-full text-white font-libre-franklin font-bold text-3xl" id='screenHomeTitle' >{copys.screenHomeTitle}</div>
        </div>
        <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-center items-center px-9 gap-3">

        {
          promos?.length > 0 && promos?.map(promo=>{
            return(
              <Card key={promo.id} promo={promo} status={promo.status}/>
            )
          })
        }
        </div>

    </div>
  )
}
