import { axiosRequest } from '../utils/axiosRequest';
import { httpVerbs } from '../utils/constants';

export const getPromos = async () => {
    return await axiosRequest(httpVerbs.GET, '/v1/coupon');
}

export const getPromoById = async (promoId) => {
    return await axiosRequest(httpVerbs.GET, '/v1/coupon/' + promoId);
}

export const activatePromo = async (promotion_id) => {
    return await axiosRequest(httpVerbs.POST, '/v1/coupon/activate', { promotion_id });
}

export const getPrivacyPolicies = async () => {
    return await axiosRequest(httpVerbs.GET, '/v1/content/site_privacy')
}

export const getTermsAndConditions = async () => {
    return await axiosRequest(httpVerbs.GET, '/v1/content/terms')
}

export const getTermsAndConditionsByPromo = async (promoId) => {
    return await axiosRequest(httpVerbs.GET, '/v1/content/terms/' + promoId)
}