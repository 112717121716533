import React from 'react'
import QRCode from 'react-qr-code'
import copys from '../utils/copys'

const CardQR = ({code}) => {
  return (
    <div className='flex px-3 flex-col justify-around py-5 items-center gap-4 rounded-xl bg-qr-card w-full'>
        <div className='flex px-2 pt-2 pb-1 flex-col items-center gap-1 rounded bg-white'>
            {
                code && (<QRCode
                            id="QRCode"
                            size={140}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={code}
                            viewBox={`0 0 140 140`}
                        />
                    )
            }
            
            <div id='text-code' className='text-black text-center font-roboto font-normal text-base'>{code}</div>
        </div>
        <div className='font-roboto text-base font-normal text-center'>
          {copys.instructionsCard}
        </div>
    </div>
  )
}

export default React.memo(CardQR)
