import { createContext, useEffect, useState } from "react";
import { getConfiguration } from "../services/commonService";
import { useToast } from "../hooks/useToast";
import { toastTypes } from "../utils/constants";

export const ConfigContext = createContext();

export const ConfigProvider = ({children})=>{

    const [countryCodes,setCountryCodes] = useState();
    const [genders, setGenders] = useState();
    const [minAndMaxBirthdateYears, setMinAndMaxBirthdateYears] = useState();

    const { showToast } = useToast();
    
    useEffect(() => {
        const fetchConfigs = async()=>{
            const configResponse = await getConfiguration();
            if(configResponse.successful){
                setCountryCodes(configResponse.data?.find(e=>e.type==='phone_code')?.values)
                setGenders(configResponse.data?.find(e=>e.type==='gender')?.values);

                const minAndMaxUserAges = configResponse.data?.find(e=>e.type==='age')?.values
                const currentYear = new Date().getFullYear();
                const maxYear = currentYear - minAndMaxUserAges?.find(e=>e.key==='min')?.value;
                const minYear = currentYear - minAndMaxUserAges?.find(e=>e.key==='max')?.value;
                setMinAndMaxBirthdateYears({maxYear,minYear});
                
            }else{
                console.error('error al cargar configuraciones',configResponse.errors)
                showToast(toastTypes.ERROR,configResponse.message);
            }
        }

        fetchConfigs();
    }, []);
    
    return (
        <ConfigContext.Provider value={{countryCodes,minAndMaxBirthdateYears,genders}}>
            {children}
        </ConfigContext.Provider>
    )
}