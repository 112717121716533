// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-carousel{
    display: flex;
    width: 336px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-8, 8px);
}

.custom-carousel-cards{
    /* display: inline-flex; */
    height: 216.32px;
    align-items: flex-start;
    gap: var(--spacing-16, 16px);
    flex-shrink: 0;
}

.custom-carousel-bottom{
    display: flex;
    width: 312px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.custom-carousel-bottom-child{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.custom-carousel-bottom-item{
    height: var(--spacing-4, 4px);
    flex: 1 0;
    border-radius: var(--radius-4, 4px);
    background: rgba(139, 142, 193, 0.50);
}

.custom-carousel-bottom-item.active{
    background: var(--secondary-icon-medium, #76A8FF);
}




[class^="slideInner___"] {
    position: initial !important ;
}`, "",{"version":3,"sources":["webpack://./src/components/carousel/Carousel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,uBAAuB;IACvB,4BAA4B;IAC5B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,SAAW;IACX,mCAAmC;IACnC,qCAAqC;AACzC;;AAEA;IACI,iDAAiD;AACrD;;;;;AAKA;IACI,6BAA6B;AACjC","sourcesContent":[".custom-carousel{\n    display: flex;\n    width: 336px;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: var(--spacing-8, 8px);\n}\n\n.custom-carousel-cards{\n    /* display: inline-flex; */\n    height: 216.32px;\n    align-items: flex-start;\n    gap: var(--spacing-16, 16px);\n    flex-shrink: 0;\n}\n\n.custom-carousel-bottom{\n    display: flex;\n    width: 312px;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n}\n\n.custom-carousel-bottom-child{\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 10px;\n    align-self: stretch;\n}\n\n.custom-carousel-bottom-item{\n    height: var(--spacing-4, 4px);\n    flex: 1 0 0;\n    border-radius: var(--radius-4, 4px);\n    background: rgba(139, 142, 193, 0.50);\n}\n\n.custom-carousel-bottom-item.active{\n    background: var(--secondary-icon-medium, #76A8FF);\n}\n\n\n\n\n[class^=\"slideInner___\"] {\n    position: initial !important ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
