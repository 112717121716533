import React from 'react'
import copys, {termsAndPrivacy} from '../../utils/copys'
import { Link } from 'react-router-dom'
import { termsAndPrivacyScreenTypes } from '../../utils/constants'

export default function Footer() {
  return (
    <footer className='flex flex-col items-center gap-2 bg-blue_strong py-6 shrink-0'>
      <div className="font-roboto text-sm font-normal text-white underline">
        <Link id='termsLink' to={'/terms/'+termsAndPrivacyScreenTypes.TERMS}>{termsAndPrivacy.termsFooterLabel}</Link>
      </div>
      <div className="font-roboto text-sm font-normal text-white underline">
        <Link id='privacyLink' to={'/terms/'+termsAndPrivacyScreenTypes.PRIVACY}>{termsAndPrivacy.privacyFooterLabel}</Link>
      </div>
      <div className="font-roboto text-sm font-normal text-white">
        {copys.footerCopyright}
      </div>
    </footer>
  )
}
