import toast from "react-hot-toast";
import Toast from "../components/Toast";

export const useToast = () => {
    const showToast = (type, message) => {
        toast.custom(t => (<Toast message={message} type={type} toastId={t?.id} />));
    }

    return { showToast }
}
