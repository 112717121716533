import React from 'react'
import {termsAndPrivacy} from '../utils/copys';
import { Link } from 'react-router-dom';
import { termsAndPrivacyScreenTypes } from '../utils/constants';

export const TermsPrivacy = () => {

    return (
        <div className="my-5 text-white text-center text-sm font-roboto">
            <span>
            {termsAndPrivacy.labelDisclaimerTerms[0]}
            <Link className='underline' to={'/terms/'+termsAndPrivacyScreenTypes.TERMS}>{termsAndPrivacy.labelDisclaimerTerms[1]}</Link>
            {termsAndPrivacy.labelDisclaimerTerms[2]}
            <Link className='underline' to={'/terms/'+termsAndPrivacyScreenTypes.PRIVACY}>{termsAndPrivacy.labelDisclaimerTerms[3]}</Link>
            {termsAndPrivacy.labelDisclaimerTerms[4]}
            </span>
        </div>
    )
}
