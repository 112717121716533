import { apiClient } from "../config/axios";
import { httpVerbs } from "./constants";
import { apiErrorHandler } from "./errorHandler";
import * as Sentry from '@sentry/react'


const getHeaders = () => {
    const headers = {};
    const userDataText = localStorage.getItem('auth');
    if (userDataText) {
        try {
            const userData = JSON.parse(userDataText);
            if (userData.token) {
                headers.headers = {
                    'Authorization': `Bearer ${userData.token}`
                }
            }
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    flow: 'load_user_data_localstorage',
                    user_data_text:userDataText,
                },
            });
            console.log('error al buscar token en localStorage', error)
        }
    }
    return headers;
}

export const axiosRequest = async (type, url, body) => {
    try {
        const config = getHeaders();
        const { data } = await makeRequestByType(type, url, body, config);
        return data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}

const makeRequestByType = (type, url, data, config) => {
    switch (type) {
        case httpVerbs.GET:
            return apiClient.get(url, config);
        case httpVerbs.POST:
            return apiClient.post(url, data, config);
        default:
            return apiClient.get(url, config);
    }
} 