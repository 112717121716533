import React from 'react'

const IconCheckboxFilled = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{flexShrink:0}} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_2973_269)">
            <path d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#76A8FF"/>
        </g>
        <defs>
            <clipPath id="clip0_2973_269">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default React.memo(IconCheckboxFilled)
