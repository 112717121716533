import React from "react";

const Button = ({ label, onClick, enabled, style, icon, id, isLoading,colorsClassName }) => {
  let colorClass=enabled || isLoading ? 'bg-primary_dark text-text_strong hover:bg-primary_dark-hover active:bg-primary_dark-active':'bg-primary_dark-disabled text-text_disabled'
  if(colorsClassName){
    colorClass=colorsClassName;
  }
  return (
    <button id={`actionButton_${id}`} style={style?style:{}} className={'flex py-2 px-4 justify-center items-center gap-1 rounded-3xl ' + colorClass} onClick={onClick} disabled={!enabled || isLoading}>
      <div className='text-center font-roboto text-base font-semibold'>{label}</div>
      {
        icon && (
          <div className="flex justify-center items-center w-6 h-6 text-center">
            {icon?.position==='end' && icon?.icon}
          </div>
        )
      }
    </button>
  );
};

export default React.memo(Button);
