import { axiosRequest } from '../utils/axiosRequest';
import { httpVerbs } from '../utils/constants';
import * as Sentry from '@sentry/react'


export const sendOtp = async (phone) => {
    try {
        const phone_number = phone.replace(' ', '');
        return await axiosRequest(httpVerbs.POST, '/v1/login/otp', { phone_number })
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                flow: 'send_otp',
                phone_number:phone
            },
        });
        console.log(error)
    }

}


export const validateOtp = async (phone, OTP) => {
    try {
        const phone_number = phone.replace(' ', '');
        const otp = Number(OTP);
        return await axiosRequest(httpVerbs.POST, '/v1/login/validate', { phone_number, otp })
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                flow: 'validate_otp',
                phone_number:phone,
                otp:OTP
            },
        });
        console.log(error);
    }
}

export const signUp = async (formData) => {
    try {
        return await axiosRequest(httpVerbs.POST, '/v1/signup', formData);
    } catch (error) {
        Sentry.captureException(error, {
            tags: {
                flow: 'send_otp',
                user_data:formData,
            },
        });
    }
}

