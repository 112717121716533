import React from 'react'

const ChipButton = ({label,onClick,colorClass,id}) => {
  return (
    <button id={`chipButton_${id}`} onClick={onClick} className={`flex items-center min-w-[70px] py-1 px-2 gap-1 rounded-[48px] text-text_strong text-center font-roboto text-xs font-normal flex-[1_0_0%] ${colorClass}`}>
        {label}
    </button>
  )
}

export default ChipButton
