import React from 'react'

import {Outlet, useLocation} from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Layout() {

  const location = useLocation();
  const isHomePage = location.pathname ==='/'|| location.pathname==='/home';
  const backgroundClass=isHomePage? 'bg-texture-blue':'bg-texture-gray';

  return (
    <div className={`min-h-screen flex flex-col justify-between ${backgroundClass} bg-center bg-cover bg-no-repeat`}>
      <div className='flex flex-col'>
        <Header/>
        <Outlet />
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}
