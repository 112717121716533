import React, { useEffect, useState } from 'react'
import copys from '../utils/copys'
import IconCheckboxFilled from './icons/IconCheckboxFilled'
import IconCheckboxEmpty from './icons/IconCheckboxEmpty'

const RadioGroup = ({required,options,groupName,defaultValue,onChange}) => {

    const [selected,setSelected] = useState(defaultValue||null);
    
    useEffect(()=>{
        onChange(selected);
    },[selected])

    const handleClickRadio = (value,isChecked)=>{
        if(required){
            setSelected(value)
        }else{
            setSelected(isChecked?null:value);
        }
    }

    return (
        <div className='flex flex-col items-start gap-1 self-stretch container'>
            <div className="self-stretch text-white font-roboto text-base font-normal">
                {copys.labelAgeRadio}
                { required && (<span className='self-stretch text-text_secondary font-roboto text-base font-normal'>*</span>)}
            </div>
            <div className="flex items-center justify-start container">
                <div className="flex flex-col items-start gap-1 w-4/5">
                    {options?.map((e)=>{
                        return (
                            <Radio onClick={handleClickRadio} key={e.key} value={e.key} groupName={groupName} label={e.value} isChecked={e.key===selected}/>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const Radio = React.memo(({label,isChecked,value,onClick})=>{
    const handleClick= ()=>{
        onClick(value,isChecked);
    }
    return (
        <div className="flex py-1 px-0 items-center gap-2">
            <div className="flex w-10 h-10 p-2 justify-center items-center" id={`radio_${value}`} onClick={handleClick}>
                {isChecked ? <IconCheckboxFilled/>:<IconCheckboxEmpty/>}
            </div>
            <div className="font-roboto text-base font-normal text-white">
                {label}
            </div>
        </div>
    )
});

export default RadioGroup
