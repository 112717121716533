import React from 'react'
import { toastTypes } from '../utils/constants';
import IconCheck from './icons/IconCheck';
import copys from '../utils/copys';
import toast from 'react-hot-toast';
import IconBell from './icons/IconBell';
import IconExclamartionMark from './icons/IconExclamartionMark';

const Toast = ({type,message,toastId}) => {

    let icon;
    let backgroundColorClass='text-white bg-text_strong';
    switch(type){
        case toastTypes.SUCCESS:
            icon = <IconCheck/>;
            backgroundColorClass='text-text_strong bg-success';
            break;
        case toastTypes.INFO:
            icon = <IconCheck/>
            backgroundColorClass='text-text_strong bg-info';
            break;
        case toastTypes.SYSTEM:
            icon = <IconBell/>
            break;
        case toastTypes.ERROR:
            icon = <IconExclamartionMark fill='#0C0D0F'/>
            backgroundColorClass='text-text_strong bg-border_danger';
            break;
        default:
            icon = <IconBell/>
            break;
    }
  return (
    <div className={`flex w-full sm:w-2/3 py-[6px] px-4 items-center gap-3 rounded shadow-toast ${backgroundColorClass}` }>
        <div className="w-6 h-6 shrink-0">
            {icon}
        </div>
        <div className="flex-[1_0_0] font-roboto text-sm font-normal">
        {message}
        </div>
        <button className="flex py-2 px-4 justify-center items-center gap-1 rounded-lg text-center font-roboto text-base font-semibold" onClick={()=>toast.remove(toastId)}>
            {copys.toastCloseButton}
        </button>
    </div>
  )
}

export default Toast
