import { createContext, useEffect, useState } from "react";

export const PromoContext = createContext();

export const PromoProvider = ({children})=>{

    // Initialize state with default values
    const [promoData, setPromoData] = useState(() => {
        // Retrieve persisted data from storage when component mounts
        const storedData = localStorage.getItem('promo');
        return storedData ? JSON.parse(storedData) : { promoId:0};
    });

    // Update state and persist data whenever it changes
    useEffect(() => {
        localStorage.setItem('promo', JSON.stringify(promoData));
    }, [promoData]);
    
    return (
        <PromoContext.Provider value={{promoData,setPromoData}}>
            {children}
        </PromoContext.Provider>
    )
}