import React from 'react'
import copys from '../../utils/copys'
import Button from '../Button'

const ModalLogout = ({onClickOk,onClickCancel}) => {
  return (
    <div className='flex flex-col py-8 px-4 w-72 justify-end items-center gap-6 bg-white'>
      <div className='flex flex-col items-center text-center  font-libre-franklin font-bold gap-3 self-stretch text-black text-xl'>{copys.modalLogoutText}</div>
      <div className="flex flex-col justify-end items-center gap-2">
        <Button label={copys.modalLogoutButtonOk} enabled onClick={onClickOk} id={'logoutConfirm'} />
        <Button label={copys.modalLogoutButtonCancel} enabled style={{backgroundColor:'inherit'}} onClick={onClickCancel} id={'logoutCancel'} />
      </div>
    </div>
  )
}

export default React.memo(ModalLogout)
