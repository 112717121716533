import React from 'react'
import { TailSpin } from 'react-loader-spinner'

const LoadingSpinner = ({width,color,strokeWidth}) => {
  return (
    <TailSpin
            visible={true}
            height={width}
            width={width}
            color={color}
            ariaLabel="tail-spin-loading"
            radius="1"
            strokeWidth={strokeWidth||3}
            wrapperStyle={{}}
            wrapperClass=""
        />
  )
}

export default LoadingSpinner
