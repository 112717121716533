import React from 'react'
import CouponBrokenImg from '../assets/ilustraciones/coupon.svg'
import copys from '../utils/copys'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '../utils/constants'

const ExpiredPromoCard = () => {

    const navigate = useNavigate()
    return (
        <div className='flex flex-col items-center gap-6'>
            <img src={CouponBrokenImg} alt="broken coupon" className='h-[80px] w-[116px]' />
            <div className="flex flex-col items-center gap-3">
                <div className="text-black text-center font-libre-franklin text-2xl font-bold">{copys.expiredCardTitle}</div>
                <div className="text-text_strong text-center font-roboto text-base font-normal">{copys.expiredCardText}</div>
            </div>
            <Button label={copys.buttonSeeMoreCoupons} enabled onClick={()=>navigate('/'+routePaths.HOME)}/>
        </div>
    )
}

export default ExpiredPromoCard
