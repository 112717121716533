import React from 'react'
import logoImg from '../assets/img/logo.png'
import { useNavigate } from 'react-router-dom';
import { routePaths } from '../utils/constants';

export function Logo({isNavbar,customWidth}) {
  const classLogo = isNavbar ? 'w-16':'w-40';

  const navigate = useNavigate();
  
  return (
    <div className='flex flex-col justify-center items-center' onClick={()=>isNavbar && navigate('/'+routePaths.HOME)}>
      <img id={isNavbar?'headerLogo':'logo'} src={logoImg} className={customWidth? customWidth : classLogo} alt="Logo"></img>
    </div>
  )
}
