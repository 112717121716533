import React from 'react'
import {Logo} from '../../components/Logo'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useLocation, useNavigate } from 'react-router-dom';
import { routePaths } from '../../utils/constants';
import IconUser from '../../components/icons/IconUser';

export default function Header() {

  const {userData,setUserData} = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname ==='/'|| location.pathname==='/home';

  const handleClick = ()=>{
    if(userData.isLogged){
      if(isHomePage){
        setUserData({...userData,navigatedFromHome:true})
      }
      navigate('/'+routePaths.PROFILE)
    }else{
      navigate('/'+routePaths.LOGIN);
    }
  }
  return (
    <header id='appHeader' className={`flex justify-between items-center shrink-0 py-2 px-4`}>
      <Logo isNavbar/>
      {
        location.pathname!=='/profile' && (
          <button id='headerButton' className='py-2 px-4' onClick={handleClick}>
            <IconUser fill={isHomePage?'white':'#0C0D0F'} size={30}/>
          </button>
        )
      }
      
    </header>
  )
}
