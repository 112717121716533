import { useContext } from "react";
import { PromoContext } from "../context/PromoContext";

export const usePromoContext = () => {
    const context = useContext(PromoContext);
    if (!context) {
        throw new Error('usePromoContext must be used within a MyProvider');
    }
    return context;
}
