import { activatePromo } from '../services/promosService';
import { toastTypes } from '../utils/constants';
import copys from '../utils/copys';
import { useToast } from './useToast';
import gtmService from '../services/gtmService';
import { useAuthContext } from './useAuthContext';
import * as Sentry from '@sentry/react'

export const useActivateCoupon = () => {

    const { showToast } = useToast();
    const { userData } = useAuthContext();

    const activateCoupon = async (promoId) => {
        const { successful, data, message, errors } = await activatePromo(promoId);
        if (!successful) {
            showToast(toastTypes.ERROR, copys.toastErrorMessageDefault);
            console.log(errors)
            Sentry.captureException(errors, {
                tags: {
                    flow: 'activate_coupon',
                    promotion_id:promoId,
                    user_id: userData?.user?.id
                },
            });
            gtmService.pushNotification('error', message)
        }
        return { successful, code: data.code };
    }

    return { activateCoupon };
}