import React from 'react'
import copys from '../utils/copys'

const CouponCounter = ({count,exchangesByPromoAllowed}) => {
    const label = `${count || 0}/${exchangesByPromoAllowed || 0} ${copys.couponCounter}`;
    return (
        <div className='w-full text-center bg-pink-gradient rounded-xl px-3 py-4 font-roboto font-extrabold text-base text-white'>
            {label}
        </div>
    )
}

export default React.memo(CouponCounter)
