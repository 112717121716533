import React from 'react'

const Modal = ({ children, isOpen, onClose, hasCloseButton, isCentered }) => {
    const classJustify = isCentered ? 'justify-center':'justify-end';
    return (
        <>
            {isOpen && (
                <div className={`bg-black/50 w-screen h-screen fixed inset-0 flex flex-col ${classJustify} items-center`} onClick={onClose}>
                    <div onClick={e=>e.stopPropagation()}>
                        {hasCloseButton && (
                            <span className="close-btn" onClick={onClose}>
                                &times;
                            </span>
                        )}
                    {children}
                    </div>
                </div>
            )}
        </>
    );
  };

export default React.memo(Modal)
