import React from 'react'
import IconArrowBack from './icons/IconArrowBack'
import copys from '../utils/copys'
import { useNavigate } from 'react-router-dom'

const ButtonBack = ({customPage,customLabel}) => {

    const navigate = useNavigate();
    return (
        <button id='buttonBack' className='flex items-center py-2 px-4 gap-1 text-text_strong text-center font-roboto text-base font-semibold' onClick={()=>customPage ? navigate(customPage):navigate(-1)}>
            <IconArrowBack/>
            { customLabel ? customLabel : copys.buttonBack}
        </button>
    )
}

export default React.memo(ButtonBack)
