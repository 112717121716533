import React from 'react'

const IconArrowBack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask id="mask0_2718_2236" style={{"maskType":"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_2718_2236)">
            <path d="M9.99953 21.6538L0.345703 12L9.99953 2.34619L11.4187 3.76539L3.18413 12L11.4187 20.2346L9.99953 21.6538Z" fill="#0C0D0F"/>
        </g>
    </svg>
  )
}

export default IconArrowBack
