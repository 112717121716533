const copys = {
    welcomeMessage: '¡Hola! Ingresa tu número celular',
    inputPhone: 'Número celular',
    labelBtnContinuar: 'Continuar',
    labelBtnLogin: 'Iniciar sesión',
    buttonCreateAccount: 'Crear cuenta',
    dontHaveAccount: '¿No tienes cuenta?',
    labelRegister: 'Únete a Descuentón',
    labelIngressOTPDetail: 'Escribe el código que recibiste en tu dispositivo móvil',
    labelResendOTP: 'Reenviar código',
    labelDisclaimerResendOTP: '*¿Aún no llega? Te podemos reenviar hasta 2 códigos cada 24 h',
    labelRegisterScreenTitle: '¡Ya casi terminas!',
    labelRegisterScreenSubTitle: 'Escribe la información solicitada',
    toastCloseButton: 'Cerrar',
    footerCopyright: `© ${new Date().getFullYear()} Descuentón`,
    headerLoginButton: 'Iniciar sesión',
    headerProfileButton: 'Perfil',
    detailCardOnHoldTitle: 'Disponible en ?? horas',
    detailCardOnHoldText: 'Descuida, tienes otras opciones',
    buttonBack: 'Regresar',
    titleProfileScreen: 'Perfil',
    profileHeaderName: 'Mi nombre es',
    profileHeaderYear: 'Año en que nací',
    profileHeaderGender: 'Me identifico como',
    buttonLogout: 'Cerrar sesión',
    inputPhoneErrorMessage: 'Tu número debe tener 10 dígitos',
    inputAgeErrorMessage: 'Debes tener más de 18 años',
    inputAgeErrorMessage100Years: '¡Vaya! ¿Tienes más de 100 años? Verifica tu edad',
    labelAgeRadio: '¿Cómo te identificas?',
    toastErrorMessageDefault: 'Hay un fallo temporal, intenta más tarde',
    screenHomeTitle: 'Refréscate, ahorra y gana',
    expiredCardTitle: 'Promoción vencida',
    expiredCardText: 'Descuida, tenemos más opciones para ti',
    buttonSeeMoreCoupons: 'Explorar promociones',
    instructionsCard: 'Presenta el código en el mostrador',
    detailCardDisabledTitle: 'La promoción ya fue canjeada',
    detailCardDisabledText: 'Descuida, hay más opciones para ti',
    loadingHomeText1: '¡Hola??!',
    loadingHomeText2: 'Estás a punto de tener las mejores promociones',
    modalLogoutText: '¿Deseas cerrar sesión?',
    modalLogoutButtonOk: 'Sí, cerrar sesión',
    modalLogoutButtonCancel: 'No, regresar',
    loadingSpinner: 'Cargando',
    loadingPromoText: `¡Estamos generando tu promoción!`,
    detailCardNotLogged: 'Debes iniciar sesión para canjear tu cupón',
    backButtonGoHome: 'Mostrar promociones',
    buttonAvailable24h: 'Disponible en ',
    labelNameInput: '¿Cómo te llamas?',
    labelYearBirthInput: '¿En qué año naciste?',
    buttonBannerAvailable: 'Cupón disponible',
    buttonBannerDisabled: 'Canjes agotados',
    couponCounter: 'cupones canjeados',
    generalConditionsPromo: 'Condiciones generales',
    buttonShowMore: 'Mostrar más',
    buttonShowLess: 'Mostrar menos',
    toastRegisterSuccessful: 'Creaste tu cuenta',
    toastLoginSuccessful: 'Iniciaste sesión',
}

export const termsAndPrivacy = {
    labelDisclaimerTerms: [
        'Al registrate aceptas los ',
        'términos, condiciones',
        ' y ',
        'políticas de privacidad',
        ' de Descuentón',
    ],
    termsFooterLabel: 'Términos y condiciones',
    privacyFooterLabel: 'Políticas de privacidad',
}

export default copys;
